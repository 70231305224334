<template>
  <v-container fluid>
    <v-row dense align="center" class="mb-4">
      <v-col cols="12" sm="8">
        <v-row>
          <v-col cols="12" sm="8">
            <common-auto-complete-items
              type="masterPartner"
              searchName="companyName"
              item-value="id"
              item-text="companyName"
              hide-details
              height="10"
              placeholder="Pilih Rekanan 3PL"
              dense
              outlined
              class="caption"
              @change="handleChangeFilterTransporter"
              v-model="companyId"
              @updateItems="updateTransporterItems"
              :filter="filterTransporterItems"
              :items="itemsTransporter"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="auto">
            <v-btn
              width="150"
              block
              color="primary"
              :disabled="!companyId"
              small
              :to="`/main/driver/create/${companyId}`"
              v-if="userAccess.canCreate"
            >
              <span>{{$_strings.driver.CREATE_DRIVER}}</span>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="auto">
            <MasterDataCsvUpload
              :importTypeProp="$_strings.importType.DRIVER"
              :uploadCsvProp="uploadCsv"
              :downloadTemplateCsvProp="downloadTemplateCsv"
              dataTypeProp="driver"
              @fetchList="fetchDriver"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="4">
        <v-row dense justify="end">
          <v-col cols="auto">
            <v-select
              hide-details
              height="5"
              v-model="filterByStatus"
              :items="statusItems"
              :placeholder="$_strings.driver.ALL_STATUS"
              outlined
              dense
              class="caption"
              @change="() => {
                $router.replace({
                  name: $route.name,
                  query: {
                    ...$route.query,
                    status: filterByStatus
                  },
                });
                setFilters()
              }"
            >
            </v-select>
          </v-col>
          <v-col cols="10">
            <v-text-field
              dense
              hide-details
              outlined
              :placeholder="$_strings.driver.SEARCH_DRIVER"
              v-model="searchText"
              ref="search"
              class="caption"
              @keyup.enter="search"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="search"> mdi-magnify </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="isLoading"
          :headers="headers"
          :items="items"
          item-key="id"
          :server-items-length="totalItems"
          class="body"
          :options.sync="pagination"
          @click:row="rowClicked"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }">
          <template v-slot:[`item.profileImageUrl`]=items>
            <v-img
              v-if="items.item.profileImageUrl"
              height="80"
              width="80"
              :src="items.item.profileImageUrl"
              contain
              aspect-ratio="1"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-img
              v-else
              height="80"
              width="80"
              src="@/assets/images/userProfile.png"
              contain
              aspect-ratio="1"
            ></v-img>
          </template>
          <template v-slot:[`item.createdBy`]=items>
            <span>{{ items.item.createdBy }}</span>
            <br/>
            <span>{{ dateFormat(items.item.createdAt) }}</span>
          </template>
          <template v-slot:[`item.isActive`]="{item}">
            <v-select
              outlined
              hide-details
              dense
              :items="statusDriverItems"
              v-model="item.isActive"
              @input="val => toggleUserActivation(item, val)"
              @click.stop.prevent
              class="select-status-sopir"
            >
            </v-select>
          </template>
          <template v-slot:[`item.isSuspended`]="{item}">
            <v-select
              v-if="item.isActive"
              hide-details
              outlined
              dense
              :items="statusSuspend"
              v-model="item.isSuspended"
              @input="val => toggleUserSuspend(item, val)"
              @click.stop.prevent class="select-status-suspend"
            >
            </v-select>
          </template>
          <template v-slot:[`item.actions`]="{item}">
            <v-tooltip v-if="userAccess.canUpdate" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  class="mr-2"
                  color="primary"
                  @click.stop.prevent="navigateToPageDriverEdit(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.UPDATE}}</span>
            </v-tooltip>
            <v-tooltip top>
              <template
                v-if="userAccess.canDelete"
                v-slot:activator="{ on, attrs }"
              >
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  icon
                  color="red"
                  @click.stop.prevent="deleteDriver(item.id)"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.DELETE}}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template
                v-if="userAccess.canUpdate"
                v-slot:activator="{ on, attrs }"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  color="primary" @click="() => resetPassword(item.id)"
                  @click.stop.prevent>
                  mdi-lock-reset
                </v-icon>
              </template>
              <span>Reset Password</span>
            </v-tooltip>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              {{$_strings.driver.PAGE_NAME}}
              <span v-if="items.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

const MasterDataCsvUpload = () => import('../Common/MasterDataCsvUpload.vue');

export default {
  name: 'component-Driver',
  components: {
    MasterDataCsvUpload,
  },
  created() {
    const {
      companyId,
      transporterName,
    } = this.$route.query;
    if (companyId) {
      if (!(this.itemsTransporter.find((item) => item.id === +companyId))) {
        this.itemsTransporter.push({
          id: +companyId,
          companyName: transporterName,
        });
      }
    }
  },
  data() {
    return {
      isLoading: false,
      companyId: +this.$route.query.companyId || null,
      searchText: this.$route.query.search || '',
      filterByStatus: this.$route.query.status || '',
      items: [],
      headers: [
        {
          text: 'Foto',
          align: 'start',
          value: 'profileImageUrl',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.driver.DRIVER_NAME,
          value: 'name',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.driver.LICENSE_NO,
          value: 'licenseNo',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.driver.CREATED_BY,
          value: 'createdBy',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.driver.STATUS,
          value: 'isActive',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.driver.SUSPEND,
          value: 'isSuspended',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: 'Aksi',
          value: 'actions',
          class: 'white--text primary text-capitalize',
          sortable: false,
          width: '150px',
        },
      ],
      statusItems: [
        {
          text: 'Semua Status',
          value: '',
        },
        {
          text: 'Aktif',
          value: 'true',
        },
        {
          text: 'Tidak Aktif',
          value: 'false',
        },
      ],
      statusDriverItems: [
        {
          text: this.$_strings.driver.ACTIVE,
          value: true,
        },
        {
          text: this.$_strings.driver.IN_ACTIVE,
          value: false,
        },
      ],
      statusSuspend: [
        {
          text: this.$_strings.driver.ACTIVE_SUSPEND,
          value: true,
        },
        {
          text: this.$_strings.driver.IN_ACTIVE_SUSPEND,
          value: false,
        },
      ],
      itemsTransporter: [],
      filterTransporterItems: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
      pagination: defaultPagination(),
      totalItems: 0,
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading && this.companyId) this.fetchDriver();
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    skipEmptyStringObject,
    handleChangeFilterTransporter() {
      const { companyName } = this.itemsTransporter.find((item) => item.id === +this.companyId);
      this.$router.replace({
        query: {
          ...this.$route.query,
          transporterName: companyName,
          companyId: this.companyId,
        },
      });
      this.setFilters();
    },
    updateTransporterItems(items) {
      this.itemsTransporter = [...this.itemsTransporter, ...items];
    },
    async rowClicked(e, { item }) {
      try {
        this.$root.$loading.show();
        const res = await this.$_services.driver.driverDetail(item.id);
        this.$router.push({
          name: 'detail-driver',
          params: {
            companyId: this.companyId,
            driverId: item.id,
            data: res.data,
          },
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
    async navigateToPageDriverEdit(item) {
      try {
        this.$root.$loading.show();
        const res = await this.$_services.driver.driverDetail(item.id);
        this.$router.push({
          name: 'edit-driver',
          params: {
            companyId: this.companyId,
            driverId: item.id,
            data: res.data,
          },
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
    setFilters() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchDriver();
    },
    search() {
      if (this.searchText !== this.$route.query.search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.searchText,
            page: 1,
          },
        });
      }
      this.setFilters();
    },
    async fetchDriver() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        name: this.searchText,
        sort: handleSortBy({ defaultValue: 'createdAt,DESC', sortBy, sortDesc }),
        isActive: this.filterByStatus,
        companyId: this.companyId,
      };
      if (filters.name) filters.name = `qLike(${filters.name})`;
      try {
        this.isLoading = true;
        const result = await this.$_services.driver.driverList(this.skipEmptyStringObject(filters));
        this.items = result.data.contents;
        this.totalItems = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    uploadCsv(formData) {
      formData.append('companyId', this.companyId);
      return this.$_services.driver.uploadCsv(formData);
    },
    downloadTemplateCsv() {
      return this.$_services.driver.fetchCsvTemplateData();
    },
    refactorPhoneNumber(value) {
      const newVal = [];
      value = value.replaceAll('-', '').replaceAll(' ', '');
      if (value.substr(0, 3) === '+62') {
        value = value.substr(3, value.length);
      }
      if (value.substr(0, 2) === '62') {
        value = value.substr(2, value.length);
      }
      if (value.substr(0, 1) === '0') {
        value = value.substr(1, value.length);
      }
      let splitPhoneNumber = [];
      splitPhoneNumber = value.split('');
      splitPhoneNumber.forEach((x, index) => {
        if (index === 2 || index === 6 || (index === 10 && splitPhoneNumber[11])) {
          newVal.push(x);
          newVal.push('-');
        } else {
          newVal.push(x);
        }
      });
      const formated = `+62 ${newVal.join('')}`;
      return formated;
    },
    async toggleUserActivation(item, val) {
      const payload = { ...item, companyId: this.companyId };
      if (!this.isPhoneNumberValid(payload.phoneNo)) payload.phoneNo = this.refactorPhoneNumber(payload.phoneNo);
      payload.licenseExp = dayjs(payload.licenseExp).format('YYYY-MM-DD');
      for (let i = 0; i < payload.vaccines.length; i += 1) {
        payload.vaccines[i].date = dayjs(payload.vaccines[i].date).format('YYYY-MM-DD');
      }
      payload.isActive = val;
      try {
        this.$root.$loading.show();
        await this.$_services.driver.driverCreateEdit(payload, item.id);
        this.$dialog.notify.success(this.$_strings.driver.DRIVER_STATUS_EDITED);
      } catch {
        this.$nextTick(() => {
          this.$set(item, 'isActive', !val);
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
    async toggleUserSuspend(item, val) {
      const payload = { ...item, companyId: this.companyId };
      payload.licenseExp = dayjs(payload.licenseExp).format('YYYY-MM-DD');
      if (!this.isPhoneNumberValid(payload.phoneNo)) payload.phoneNo = this.refactorPhoneNumber(payload.phoneNo);
      for (let i = 0; i < payload.vaccines.length; i += 1) {
        payload.vaccines[i].date = dayjs(payload.vaccines[i].date).format('YYYY-MM-DD');
      }
      payload.isSuspended = val;
      try {
        this.$root.$loading.show();
        await this.$_services.driver.driverCreateEdit(payload, item.id);
        this.$dialog.notify.success(this.$_strings.driver.EDIT_STATUS_SUSPEND_MSG);
      } catch {
        this.$nextTick(() => {
          this.$set(item, 'isSuspended', !val);
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
    deleteDriver(driverId) {
      this.$dialog.warning({
        text: this.$_strings.driver.DELETE_DRIVER_CONFIRM_MSG,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then((userRes) => {
        if (userRes) {
          this.$root.$loading.show();
          this.$_services.driver.deleteDriver(driverId, this.companyId)
            .then((res) => {
              this.$dialog.notify.success('Berhasil menghapus data driver');
              this.fetchDriver();
            }).finally(() => {
              this.$root.$loading.hide();
            });
        }
      });
    },
    resetPassword(driverId) {
      this.$dialog.warning({
        text: this.$_strings.driver.RESET_DRIVER_CONFIRM_MSG,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then((userRes) => {
        if (userRes) {
          this.$root.$loading.show();
          this.$_services.driver.resetPassword(driverId)
            .then((res) => {
              this.$dialog.notify.success('Berhasil melakukan Reset Password Sopir');
              this.fetchDriver();
            }).finally(() => {
              this.$root.$loading.hide();
            });
        }
      });
    },
  },
};
</script>
